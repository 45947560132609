import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects and Memberships" keywords={[`amy conroy`, `legal engineer`, `legal tech`, `data scientist`]} />
     <div>
      <h2> Memberships </h2>
  </div>
    <div>
      <h4><a href = "https://www.bristol.ac.uk/study/postgraduate/taught/llm-law-innovation--technology/#:~:text=The%20LLM%20in%20Law%2C%20Innovation,University%20of%20Bristol%20Law%20School.">University of Bristol LegalTech Industry Board Members</a></h4>
      <p>Member of the LegalTech Industry Board at the University of Bristol. We consult on the LLM Law, Innovation and Technology masters programme. </p>
      <p>This includes guest lectures, mentoring students, and regular board meetings to consult on the industry to shape the curriculum of the programme. </p>
    </div>
   <div>
  
      <h2> Current Projects </h2>
  </div>
    <div>
      <h4><a href = "www.lawschool2-0.com">Law School 2.0</a></h4>
      <p>I am the co-founder of Law School 2.0, an organisation providing practical experiences to learn you what you've wished you'd been taught in law school.. </p>
      <p>We successfully organised the first ever public LegalTech Vacation Scheme for two years running, with the 2022 edition attended by over 700 students and 40 speakers. </p>
    </div>
    <div>
      <h4><a href = "https://ebooks.iospress.nl/doi/10.3233/FAIA200875">SUMO: SUmmarisation with Majority Opinion</a></h4>
      <p>Developing the SUMO system, an automatic case judgment summarisation system that extends my MSc thesis research.</p>
      <p>SUMO (SUmmarisation with Majority Opinion (SUMO)) integrates and extends two prior approaches for abstractively and extractively summarising UK House of Lords cases. This method applys natural language processing and machine learning, Conditional Random Fields (CRFs).</p>
    </div>
     <div>
      <h2> Previous Projects </h2>
  </div>
    <div>
      <h4>openTenancy</h4>
      <p>Co-founder of openTenancy, an open source access to justice platform that provides free tenancy guidance. Using an automated questionnaire users are guided through their tenancy issue, saving them the time of having to search through multiple different articles across the internet.</p>
    </div>
  </Layout>
)

export default ProjectsPage
